<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" label-width="77px" class="ele-form-search"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
      </el-form>
      <!-- 操作按钮 -->
     <div class="ele-table-tool ele-table-tool-default">
      <el-button @click="showEdit=true"  class="ele-btn-icon addbtn" size="small" v-if="permission.includes('sys:notice:add')">添加注销账号须知
      </el-button>
     </div>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="side_name" label="显示版"  show-overflow-tooltip>
            <template slot-scope="{row}">
                  {{ row.side_type == 1 ?'可蚁点用户版':(row.side_type == 2 ?'可蚁点司机版':
                (row.side_type == 3 ?'可蚁点跑腿版':(row.side_type == 4 ?'可蚁点商家版':'暂无对应版本'
                )))}}
              </template>
          </el-table-column>
          <el-table-column label="更新时间"  show-overflow-tooltip >
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="230px" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:notice:edit')">编辑</el-link>
<!--              <el-popconfirm title="确定要删除此通知吗？" @confirm="remove(row)" class="ele-action">-->
<!--                <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:notice:delete')">删除</el-link>-->
<!--              </el-popconfirm>-->
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'修改注销账号须知':'添加注销账号须知'" :visible.sync="showEdit"
               @closed="editForm={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="82px">
        <el-row :gutter="15">
          <el-col :sm="12">
            <el-form-item label="显示版:">
              <el-select v-model="editForm.side_type" placeholder="请选择" clearable class="ele-fluid" :disabled="editForm.id?true:false">
                <el-option  label="可蚁点用户版" value="1"/>
                <el-option label="可蚁点司机版" value="2"/>
                <el-option label="可蚁点跑腿版" value="3"/>
                <el-option label="可蚁点商家版" value="4"/>
                <!-- <el-option label="可蚁点用户版" value="5"/>
                <el-option label="可蚁点司机版" value="6"/>
                <el-option label="可蚁点跑腿版" value="7"/>
                <el-option label="可蚁点商家版" value="8"/> -->
                <!-- <el-option label="可蚁点小程序版" value="9"/> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 富文本编辑器 -->
        <tinymce-editor v-model="editForm.content" :init="editContent"/>
      </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { mapGetters } from "vuex";
export default {
  name: "SysNotice",
  components: {TinymceEditor},
  data() {
    return {
      table: {url: '/notice/cancel_list', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {source:1,status:1,is_top:2},  // 表单数据
      editRules: {  // 表单验证规则
        title: [
          {required: true, message: '请输入通知标题', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请输入选择通知状态', trigger: 'blur'}
        ],
        source: [
          {required: true, message: '请输入选择通知来源', trigger: 'blur'}
        ],
        is_top: [
          {required: true, message: '请输入选择是否置顶', trigger: 'blur'}
        ],
      },
      // 自定义文件上传(这里使用把选择的文件转成blob演示)
      file_picker_callback: (callback, value, meta) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        // 设定文件可选类型
        if (meta.filetype === 'image') {
          input.setAttribute('accept', 'image/*');
        } else if (meta.filetype === 'media') {
          input.setAttribute('accept', 'video/*');
        }
        input.onchange = () => {
          let file = input.files[0];
          let reader = new FileReader();
          reader.onload = (e) => {
            let blob = new Blob([e.target.result], {type: file.type});
            callback(URL.createObjectURL(blob));
          };
          reader.readAsArrayBuffer(file);
        }
        input.click();
      }
    }
  },
  computed: {
    ...mapGetters(["permission"]),

    editContent() {
      return {
        menubar: false,
        // file_picker_callback: this.file_picker_callback,
        skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
        content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
      };
    }
  },
  mounted() {
  },
  methods: {
    /* 显示编辑 */
    edit(row) {
      this.$http.post('/notice/info', {id:row.id}).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
          if(this.editForm.side_type==1){
            this.editForm.side_type='可蚁点用户版'
          }else if(this.editForm.side_type==2){
            this.editForm.side_type='可蚁点司机版'
          }else if(this.editForm.side_type==3){
            this.editForm.side_type='可蚁点跑腿版'
          }else if(this.editForm.side_type==4){
            this.editForm.side_type='可蚁点商家版'
          }else if(this.editForm.side_type==5){
            this.editForm.side_type='可蚁点用户版'
          }else if(this.editForm.side_type==6){
            this.editForm.side_type='可蚁点司机版'
          }else if(this.editForm.side_type==7){
            this.editForm.side_type='可蚁点跑腿版'
          }else if(this.editForm.side_type==8){
            this.editForm.side_type='可蚁点商家版'
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
      this.showEdit = true;
    },
    /* 保存编辑 */
    save() {
      this.editForm.notice_type=6
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          if(this.editForm.side_type=='可蚁点用户版'){
            this.editForm.side_type=1
          }else if(this.editForm.side_type=='可蚁点司机版'){
            this.editForm.side_type=2
          }else if(this.editForm.side_type=='可蚁点跑腿版'){
            this.editForm.side_type=3
          }else if(this.editForm.side_type=='可蚁点商家版'){
            this.editForm.side_type=4
          }else if(this.editForm.side_type=='可蚁点用户版'){
            this.editForm.side_type=5
          }else if(this.editForm.side_type=='可蚁点司机版'){
            this.editForm.side_type=6
          }else if(this.editForm.side_type=='可蚁点跑腿版'){
            this.editForm.side_type=7
          }else if(this.editForm.side_type=='可蚁点商家版'){
            this.editForm.side_type=8
          }
          this.$http.post('/notice/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
</style>